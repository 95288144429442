import { BenefitProgram } from '../contracts/programs'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const benefitLogo: Record<BenefitProgram, string> = {
  [BenefitProgram.FriendsAndFamily]: '/assets/friends-and-family.svg',
  [BenefitProgram.Kameleon]: '/assets/kameleon-new.png',
  [BenefitProgram.MilesAndMore]: '/assets/miles-more.svg'
}

export const MILES_PER_PLN = 0.25
