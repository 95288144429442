










































































































































































































































import { Component, Inject as VueInject, Mixins } from 'vue-property-decorator'
import { AnyObject, ResourceActionFailed } from '@movecloser/front-core'

import {
  ConnectorErrors,
  defaultProvider,
  Inject,
  IS_MOBILE_PROVIDER_KEY,
  logger
} from '../../../support'
import {
  MappedOrder,
  OrderDiscount,
  ProductOrderData,
  shippingMethodsIconsRegistry,
  SingleOrderData
} from '../../../contexts/orders/contracts/orders'
import { ISiteService, SiteServiceType } from '../../../contexts'
import { StructureConfigurable } from '../../../support/mixins'

import { benefitLogo } from '../../loyalty/shared/contracts'
import { BenefitProgram } from '../../loyalty/contracts/programs'
import { CartItemProps } from '../../checkout/molecules/CartItem/CartItem.contracts'
import { CART_BUNDLE_DISCOUNT_PREFIX } from '../../checkout/contracts/cart'
import { CheckoutServiceType, ICheckoutService } from '../../checkout/services/checkout'
import { ILoyaltyService, LoyaltyServiceType } from '../../loyalty/contracts/services'
import { IProductsRepository, ProductsRepositoryType } from '../../products/contracts/repositories'
import { Loader } from '../../shared/molecules/Loader'
import { OrdersList } from '../../orders/organisms/OrdersList'
import { ProfileWrapper } from '../../profile/shared'
import { SingleCartItem } from '../../checkout/organisms/SingleCartItem'

import { IOrdersRepository, OrdersRepositoryType } from '../contracts/repository'
import { OrdersListItemPayment } from '../molecules/OrdersListItem'
import { translateMissingOrderItems, translateToOrderItem } from '../support/order.helpers'
import { RouteNames as OrderRouteNames } from '../routes'
import {
  ORDERS_LIST_ITEM_COMPONENT_CONFIG_MAP,
  ORDERS_LIST_ITEM_COMPONENT_KEY,
  OrdersListItemConfig,
  OrdersListItemLayout
} from '../organisms/OrdersList/OrderList.config'
import { OrderStatusesMixin } from '../../shared/mixins/orderStatuses.mixin'
import MilesAndMoreBar from '../organisms/MilesAndMoreBar/MilesAndMoreBar.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Order>({
  name: 'Order',
  components: {
    Loader,
    MilesAndMoreBar,
    OrdersList,
    OrdersListItemPayment,
    ProfileWrapper,
    SingleCartItem
  },
  async created () {
    this.config = this.getComponentConfig(
      ORDERS_LIST_ITEM_COMPONENT_KEY,
      { ...ORDERS_LIST_ITEM_COMPONENT_CONFIG_MAP }
    )

    await this.loadLoyalty()
    await this.loadOrder(this.$route.params.id)
  }
})
export class Order extends Mixins(StructureConfigurable, OrderStatusesMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  @Inject(LoyaltyServiceType)
  protected readonly loyaltyService!: ILoyaltyService

  @Inject(OrdersRepositoryType)
  protected readonly ordersRepository!: IOrdersRepository

  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  protected config!: OrdersListItemConfig

  /**
   * Error message
   */
  public errorMessage: string | null = null
  /**
   * Determines the loading state of the component.
   */
  public isLoading: boolean = true
  /**
   * Single order collection
   */
  public order: SingleOrderData[] | null = null
  /**
   * Collection of orders with mapped data
   */
  public mapped!: MappedOrder | null
  /**
   * Collection of products ordered by customer but not resolved (missing, withdrawn, etc...)
   */
  public notFoundItems: CartItemProps[] = []
  /**
   * Miles & More points
   */
  public milesAndMorePoints: number | undefined = undefined
  /**
   * Miles & More points limit
   */
  public milesAndMorePointsLimit: number | undefined = undefined

  public get layout () {
    return OrdersListItemLayout
  }

  public get ordersListItemLayout (): string {
    return this.getConfigProperty('layout')
  }

  public get shouldSeparateBundleDiscounts (): boolean {
    return this.getConfigProperty('shouldSeparateBundleDiscounts')
  }

  public get showGiftCardPayment (): boolean {
    return this.getConfigProperty('showGiftCardPayment')
  }

  public get paymentIcons (): Record<string, string> {
    return this.getConfigProperty('paymentIcons')
  }

  public get allOrdersRoute (): { name: string } {
    return {
      name: `orders.${OrderRouteNames.History}`
    }
  }

  public get hasBundleDiscounts (): boolean {
    return this.shouldSeparateBundleDiscounts && typeof this.bundleDiscounts !== 'undefined' && this.bundleDiscounts.length > 0
  }

  public get hasGiftCard (): boolean {
    return !!this.mappedOrder && Array.isArray(this.mappedOrder.appliedAmastyGiftCards) && this.mappedOrder.appliedAmastyGiftCards.length > 0
  }

  /**
   * ATTENZIONE!
   * Zastosowano frontowy fix na błąd po stronie API.
   * https://redmine.movecloser.pl/issues/42215
   *
   * API potrzebuje jakiejś metody płatności przy składaniu zamówienia.
   * Najwyraźniej zapisuje je w trakcie jak metody są zmieniane.
   *
   * Jeżeli wybierzemy:
   * 1) płatność BLIK
   * 2) potem Voucher (To ważne, bo voucher nie jest metodą płatności per se),
   * 3) a następnie zmienimy na płatność przy dostawie (bo voucher nie pokrywa całej kwoty zamówienia)
   * to API na podsumowaniu zamówienia
   * będzie dalej zwracać informacje o użyciu BLIKa jako metody płatności.
   *
   * Fix jest brzydki i polega na wyciągnięciu z metody dostawy informacji o płatności przy dostawie,
   *
   * TLDR: W tym przypadku API nie powinno zwracać informacji, że użyto BLIKa.
   * Po poprawieniu w API mozna usunąc metodę `isCODPayment` i jej użycie.
   *
   * CIEKAWOSTKA: W Magento w widoku zamówień to wygląda wszystko poprawnie.
   * Błąd widoczny jest jedynie w panelu usera - /konto/zamowienie/:id
   */
  public get isCODPayment (): boolean {
    return !!this.mappedOrder && this.mappedOrder.shippingMethod.includes('pobranie')
  }

  public get giftCardImage (): string {
    let image = ''
    try { // todo: wrapped in try-cacth, as it breaks backoffice - fix import!
      image = require('../../../../../../assets/images/payments/giftcard.png')
    } catch (e) {}

    return image
  }

  public get giftCardTitle (): string {
    return this.$t('front.orders.views.OrderView.giftCards.title').toString()
  }

  public get giftCardValueExceedsSubtotal (): boolean {
    return this.hasGiftCard && this.giftCardValue > this.mappedOrder!.costs.total
  }

  public get giftCardValue (): number {
    return this.hasGiftCard ? this.mappedOrder!.appliedAmastyGiftCards!.reduce((
      acc,
      current
    ) => acc + current.value, 0) : 0
  }

  public get mappedOrder (): MappedOrder | null {
    return this.mapped
  }

  public get regularDiscounts (): Array<OrderDiscount> | undefined {
    if (!this.mapped || !this.mapped.discounts || this.mapped.discounts.length === 0) {
      return
    }

    if (this.shouldSeparateBundleDiscounts) {
      return this.mapped.discounts.filter(discount => !discount.label.toLowerCase()
        .includes(CART_BUNDLE_DISCOUNT_PREFIX.toLowerCase()))
    }

    return this.mapped.discounts
  }

  public get bundleDiscounts (): Array<OrderDiscount> | undefined {
    if (!this.mapped || !this.mapped.discounts || this.mapped.discounts.length === 0) {
      return
    }

    const bundleDiscounts = this.mapped.discounts.filter(discount => discount.label.toLowerCase()
      .includes(CART_BUNDLE_DISCOUNT_PREFIX.toLowerCase()))

    if (bundleDiscounts.length === 0) {
      return
    }

    return bundleDiscounts.map((discount) => {
      return {
        amount: discount.amount,
        label: ''
      }
    })
  }

  public get returnOrderPath () {
    return this.siteService.getActiveSiteUrls().returnOrder
  }

  /**
   * Get status that should enable retry payment action
   */
  public get statusesToReturn (): Array<string> {
    return Object.keys(this.returnOrderStatuses)
  }

  public get shippingIcon (): string | null | undefined {
    if (!this.mapped) {
      return null
    }

    const iconsRegistry = Object.values(shippingMethodsIconsRegistry)
    return iconsRegistry.find((iconName) => {
      return this.mapped?.shippingMethod.toLocaleLowerCase().startsWith(iconName.toLowerCase())
    })
  }

  public get milesAndMoreLogo (): string {
    return benefitLogo[BenefitProgram.MilesAndMore]
  }

  /**
   * Load single order data
   * @param order
   * @protected
   */
  protected async loadOrder (order: string) {
    this.isLoading = true

    try {
      const response: SingleOrderData | null = await this.ordersRepository.loadSingleOrder(order)

      if (!response) {
        return
      }

      /** Order SKUs collection */
      const skus: string[] = []
      for (const [k, v] of Object.entries(response)) {
        if (k === 'products') {
          for (const product of Object.values(v as ProductOrderData)) {
            skus.push(product.sku)
          }
        }
      }

      if (skus.length === 0) {
        return
      }

      try {
        /** Load order products data */
        const productsResponse = await this.productsRepository.loadProductsBySkus(skus)

        if (!productsResponse) {
          return
        }

        /** Compose finally shaped order data */
        this.mapped = {
          ...response,
          products: translateToOrderItem(response.products, productsResponse)
        }

        /** Find products that couldn't be resolved (are missing, withdrawn, etc...) */
        const foundSkus: (string | undefined)[] = []
        for (const product of this.mapped.products) {
          foundSkus.push(product.sku)
        }

        this.notFoundItems = translateMissingOrderItems(
          response.products.filter((item) => !foundSkus.includes(item.sku)),
          this.$i18n
        )

        if (response.paymentMethods[0].type === 'przelewy24') {
          try {
            /** Retrieve order payment method data */
            const paymentMethods = await this.checkoutService.loadPaymentMethods(this.mapped.costs.total)

            if (!paymentMethods) {
              return
            }

            this.mapped = {
              ...this.mapped,
              paymentMethod: paymentMethods.filter((method: AnyObject) => method.id === this.mapped?.paymentMethod)[0]
            }
          } catch (e) {
            this.setError(e as Error)
          }
        } else {
          const payment = response.paymentMethods[0]

          this.mapped = {
            ...this.mapped,
            paymentMethod: {
              name: payment.name,
              id: 0,
              active: true,
              imgUrl: this.paymentIcons[payment.type],
              mobileImgUrl: this.paymentIcons[payment.type]
            }
          }
        }
      } catch (e) {
        this.setError(e as Error)
      }
    } catch (e) {
      this.setError(e as Error)
    } finally {
      this.isLoading = false
    }
  }

  public async loadLoyalty (): Promise<void> {
    const loyalty = await this.loyaltyService.fetch()
    const payload = loyalty.getPayload()

    this.milesAndMorePoints = payload.milesAndMorePoints
    this.milesAndMorePointsLimit = payload.milesAndMorePointsLimit
  }

  /**
   * Determines the type of error and logs its message.
   */
  private setError (error: Error): void {
    if (error instanceof ResourceActionFailed) {
      if (error.status === ConnectorErrors.ServerError ||
        error.status === ConnectorErrors.Unknown) {
        logger(error.message, 'error')
      }
    } else {
      logger(error.message, 'error')
    }

    this.errorMessage = error.message
  }
}

export default Order
