

































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import {
  MilesAndMoreBarPayload
} from './MilesAndMoreBar.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<MilesAndMoreBar>({
  name: 'MilesAndMoreBar'
})
export class MilesAndMoreBar extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: String, required: true })
  public logo!: MilesAndMoreBarPayload['logo']

  @Prop({ required: true })
  public milesAndMore!: MilesAndMoreBarPayload['milesAndMore']

  @Prop({ required: true })
  public milesAndMorePoints?: MilesAndMoreBarPayload['milesAndMorePoints']

  @Prop({ required: true })
  public milesAndMorePointsLimit?: MilesAndMoreBarPayload['milesAndMorePointsLimit']

  @Prop({ required: true })
  public milesAndMorePointsOrder?: MilesAndMoreBarPayload['milesAndMorePointsOrder']

  public get milesAndMoreStaticPage (): string | undefined {
    return this.siteService.getActiveSite().properties.milesMore as string | undefined
  }
}

export default MilesAndMoreBar
