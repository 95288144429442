








import { Component, Mixins, Prop } from 'vue-property-decorator'

import { IToastMixin, ToastMixin } from '../../../shared'

import { Action as CartItemActions } from '../../molecules/CartItem/CartItem.contracts'
import { CartItem } from '../../molecules/CartItem'
import { CartItemAction } from '../../molecules/CartItem/CartItem.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 *
 * @emits [removeFromCartCb]
 */
@Component({ name: 'SingleCartItem', components: { CartItem } })
export class SingleCartItem extends Mixins<IToastMixin>(ToastMixin) {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCheckout!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isDisabled!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isGift!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isSample!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isOutOfStock!: boolean

  @Prop({ type: Object, required: true })
  public readonly item!: any

  @Prop({ type: Number, required: false })
  public readonly quantity!: number

  @Prop({ type: Function, required: false })
  public readonly quantityCb!: (change: number) => Promise<boolean> | boolean

  @Prop({ type: Function, required: false })
  public readonly removeFromCartCb!: (uid: string) => Promise<void>

  @Prop({ required: false, type: Boolean, default: true })
  public shouldDisableQuantity?: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public shouldDisablePerItemQuantity?: boolean

  @Prop({ required: false, type: Number })
  public sellableQuantity?: number

  @Prop({ type: String, required: false })
  public readonly size!: string

  @Prop({ type: String, required: false })
  public readonly sku?: string

  @Prop({ type: String, required: true })
  public readonly uid!: string

  /**
   * Determines actions of cart item.
   */
  public get actions (): CartItemActions[] {
    if (this.isDisabled) {
      return []
    }

    return [
      {
        label: this.$t('front.checkout.organisms.SingleCartItem.actions.delete.label').toString(),
        key: CartItemAction.Delete,
        icon: {
          color: '#5F5F5F',
          height: '1em',
          name: 'TrashIcon',
          width: '1em'
        },
        onClick: () => this.removeFromCartCb(this.uid)
      }
    ]
  }

  public onIsLoadingHandler (value: boolean): void {
    this.$emit('isLoading', value)
  }
}

export default SingleCartItem
